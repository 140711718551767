import React from "react"
import Layout from "../layout/layout"
import rahul from "../images/me.jpg"
import {graphql,Link} from 'gatsby'
import Post from "../components/PostStyle"
const mystyle=`
/*.newsletter-section::after {
    content: '';
    position: absolute;
    background:linear-gradient(rgba(219, 219, 219, 0.2),rgba(115, 115, 115, 0.05));
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    border-radius:10px;
    transition:1s;
    z-index: -1;
    box-shadow: 0 0 56px rgba(69, 185, 255, 0.4);
  }*/
  body::after {
    content:'';
    display: block;
    position: fixed;
    width: 70vmin;
    height: 70vmin;
    right: 10%;
    bottom: 10%;
    animation: bradius 10s linear infinite alternate, spin 26s linear infinite reverse;
    transform-origin: 20% 20%;
    z-index:-1;
    background: rgba(251, 110, 110, 0.06);//rgba(110, 185, 251, 0.06)
}
.dark::after  {
    background:rgba(110, 185, 251, 0.06);
}
`
//.profile:hover .newsletter-section::after{transform: rotate(15deg)};*

const IndexPage=({data}) =>{
    return(
        <Layout title="Home">
          <style>{//mystyle
          }
          </style>
          <div className="profile">
            <div className="me">
              <h1>Hi, <mark>I'm Rahul</mark></h1>
              <p>A full stack developer and designer creating open source mini-projects and writing about modern programming world and new technologies with <b>little secrets</b>.
              <br/> <code>Web, AI, ML, AR, Hacks, IoT, Visulization, Automation, Docker</code></p>
            </div>
            <div className="newsletter-section">
              <img src={rahul} className="newsletter-avatar" alt="Rahul" />
              <div>
                <h3>Get Updates</h3>
                <p>Making things simple and easy with very basic level</p>
                <a className="button" href="https://linkedin.com/company/rahul3v">Follow</a>
              </div>
            </div>
          </div><br/><br/>
            <h1>Latest Articles!  <Link to="/blog" className="main_links">View all</Link></h1>
            <section className="posts">{data.latest.edges.map((edge,index)=>{
                return(
                <div className="post" key={index}>
                  <Post postEdges={edge}/>
                </div>
                )
            }

            )}</section>
            <p>Welcome to the secret blog, hope it will help you in solving some of the doutful problems of yours and much more.</p>
        </Layout>
    )
}
export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 6
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail
            date(formatString:"Do MMM, YYYY")
            template
          }
        }
      }
    }
  }
`
